/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { ObjectId } from 'bson';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormTemplate, IApolloClient } from '@vamstar/fox-frontend-common';
import { IUser } from '@vamstar/fox-api-common/esm/user/types';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { log } from '@vamstar/fox-node-logger';
import { IOrganisationUser } from '@vamstar/fox-api-common/esm/organisation-user/types';

import { CREATE_USER, UPDATE_USER, UPDATE_USER_ORGANISATION } from '../mutations';
import { fetchOrganisationUserById, fetchUserById } from '../utils';
import { UserFormFields } from './UserFormfields';

interface MatchParams {
  _id: string;
}

interface IUserState {
  isLoading: boolean;
  organisationUser: Partial<IOrganisationUser>;
  user: Omit<IUser, 'invalidLoginAttempts'>;
  selectedRoles: [];
  selectedStatus: [];
}

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

class UserFormView extends React.Component<HOCProps> {
  public state: IUserState = {
    isLoading: false,
    user: {
      _id: this.props.match.params._id,
      basicInfo: {
        email: '',
        roles: [],
        userStatus: [],
        firstName: '',
        lastName: '',
      },
      password: '',
    },
    organisationUser: { organisation: { emailDomain: '', name: '' } },
    selectedRoles: [],
    selectedStatus: [],
  };

  async componentDidMount() {
    const {
      user: { _id },
    } = this.state;
    if (_id) {
      this.setState({ isLoading: true });

      await this.getUserById(_id);

      this.setState({ isLoading: false });
    }
  }

  getUserById = async (id: string | ObjectId) => {
    const { client } = this.props;
    const user = await fetchUserById(client, id);
    if (user) {
      const organisationUser = await fetchOrganisationUserById(client, user._id);

      const selectedRoles = user.basicInfo.roles.map((name: string) => {
        return { name };
      });
      const selectedStatus = user.basicInfo.userStatus.map((name: string) => {
        return { name };
      });

      this.setState({
        user,
        organisationUser,
        selectedRoles,
        selectedStatus,
      });
    }
  };

  onSave = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const {
      user,
      user: {
        basicInfo: { firstName, lastName, email },
        password,
      },
      selectedRoles,
      selectedStatus,
    } = this.state;

    const roles = selectedRoles.map((item: { name: string }) => item.name);
    const userStatus = selectedStatus.map((item: { name: string }) => item.name);
    try {
      const response = await client.mutate({
        mutation: CREATE_USER,
        variables: {
          data: { basicInfo: { firstName, lastName, email: email.trim(), roles, userStatus }, password },
        },
        fetchPolicy: 'no-cache',
      });

      const { _id } = response.data.createUser;
      user._id = _id;
      this.setState({ user });
      this.props.history.push({
        pathname: `/user/${_id}`,
      });
      enqueueSnackbar(t('recordCreated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const {
      user: {
        _id,
        basicInfo: { firstName, lastName, email },
      },
      selectedRoles,
      selectedStatus,
      organisationUser,
    } = this.state;

    const roles = selectedRoles.map((item: { name: string }) => item.name);
    const userStatus = selectedStatus.map((item: { name: string }) => item.name);

    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          data: {
            filter: { id: _id },
            doc: {
              basicInfo: {
                firstName,
                lastName,
                email: email.trim(),
                roles,
                userStatus,
              },
            },
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (organisationUser.organisation.emailDomain.length > 0) {
        await client.mutate({
          mutation: UPDATE_USER_ORGANISATION,
          variables: {
            userId: _id,
          },
          fetchPolicy: 'no-cache',
        });
      }

      enqueueSnackbar(t('recordUpdated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  isEmailValid = () => {
    const { email } = this.state.user.basicInfo;
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  isPasswordValid = () => {
    const {
      user: { _id, password },
    } = this.state;

    return _id === undefined && (password === undefined || password.length < 6);
  };

  isNameValid = () => {
    const {
      user: {
        basicInfo: { firstName, lastName },
      },
    } = this.state;

    return !firstName || firstName === '' || !lastName || lastName === '';
  };

  onChangeFirstName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      user,
      user: { basicInfo },
    } = this.state;
    this.setState({
      user: {
        ...user,
        basicInfo: {
          ...basicInfo,
          firstName: e.target.value,
        },
      },
    });
  };

  onChangeLastName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      user,
      user: { basicInfo },
    } = this.state;
    this.setState({
      user: {
        ...user,
        basicInfo: {
          ...basicInfo,
          lastName: e.target.value,
        },
      },
    });
  };

  onChangeEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      user,
      user: { basicInfo },
    } = this.state;
    this.setState({
      user: {
        ...user,
        basicInfo: {
          ...basicInfo,
          email: e.target.value,
        },
      },
    });
  };

  onChangePassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        password: e.target.value,
      },
    });
  };

  onChangeRoles = (data: any) => {
    this.setState({ selectedRoles: data });
  };

  onChangeUserStatus = (data: any) => {
    this.setState({ selectedStatus: data });
  };

  isSaveDisabled = (): boolean => {
    const { selectedRoles, selectedStatus } = this.state;

    return !this.isEmailValid() || selectedRoles.length === 0 || selectedStatus.length === 0 || this.isPasswordValid();
  };

  render() {
    const {
      isLoading,
      user: { _id },
      user,
      organisationUser,
      selectedRoles,
      selectedStatus,
    } = this.state;

    return (
      <FormTemplate
        isLoading={isLoading}
        onSubmit={() => (_id ? this.onUpdate() : this.onSave())}
        isEdit={!!_id}
        formLabel="User"
        isFormDisabled={this.isSaveDisabled()}
      >
        <UserFormFields
          _id={_id}
          user={user}
          organisationUser={organisationUser}
          selectedRoles={selectedRoles}
          selectedStatus={selectedStatus}
          isEmailValid={this.isEmailValid}
          isPasswordValid={this.isPasswordValid}
          onChangeFirstName={this.onChangeFirstName}
          onChangeLastName={this.onChangeLastName}
          onChangeEmail={this.onChangeEmail}
          onChangePassword={this.onChangePassword}
          onChangeRoles={this.onChangeRoles}
          onChangeUserStatus={this.onChangeUserStatus}
        />
      </FormTemplate>
    );
  }
}

const UserForm: React.ComponentClass<any, IUserState> = compose<HOCProps, any>(
  withApollo,
  withRouter,
  withSnackbar,
  withTranslation('common'),
)(UserFormView);

export default UserForm;
