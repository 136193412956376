import { ApolloClient } from '@apollo/client';
import { IOrganisationUser } from '@vamstar/fox-api-common/esm/organisation-user/types';
import { IUser } from '@vamstar/fox-api-common/esm/user/types';
import { log } from '@vamstar/fox-node-logger';
import { ObjectId } from 'bson';

import { ORGANISATION_BY_USER_ID, USER_BY_ID } from './queries';

export const fetchUserById = async (client: ApolloClient<any>, id: string | ObjectId): Promise<IUser | null> => {
  try {
    const { data } = await client.query({
      query: USER_BY_ID,
      variables: {
        id,
      },
    });
    return data.user;
  } catch (err) {
    log.error(`Error while fetching user ${err}`);
  }
  return null;
};

export const fetchOrganisationUserById = async (
  client: ApolloClient<any>,
  userId: string | ObjectId,
): Promise<Partial<IOrganisationUser>> => {
  try {
    const { data } = await client.query({
      query: ORGANISATION_BY_USER_ID,
      variables: {
        userId,
      },
    });
    return data.getOrganisationUser;
  } catch (err) {
    log.error(`Error while fetching organisation of user  ${err}`);
  }
  return { organisation: { emailDomain: '', name: '' } };
};
